import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const Privacy = () => {
  return (
    <Layout>
      <SEO
        title="Privacy"
        description="Your privacy is important to Contego Spa Designs, Inc"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa Massage chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa Massage chairs for sale,Cheap spa pedicure chairs,pedicure spa Massage chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />

      <div className="container py-4">
        <div className="row py-5">
          <div className="col">
            <h1 className="w-100 text-center">PRIVACY POLICY</h1>
            <p className="text-center">Version 1.0</p>
            <p className="text-center">Effective Date: 06/01/2020</p>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <p>
              Your privacy is important to Contego Spa Designs, Inc. (“Company”, “we”, “us” or
              “our”). We understand and respect your concerns about privacy and are committed to
              providing you with a rewarding and safe experience without regard to the manner in
              which you conduct business with us. This policy describes the types of information we
              may collect from you or that you may provide, how we use and when we disclose such
              information, and your choices regarding our access to and use of such information.
            </p>

            <p>Our Privacy Policy explains:</p>

            <ul>
              <li>
                <p>What information we collect and why we collect it.</p>
              </li>
              <li>
                <p>How we use and share or disclose that information.</p>
              </li>
              <li>
                <p>How to access and update your information.</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <h3>Information We Collect</h3>

            <p>
              We collect several different ways information that identifies, relates to, describes,
              or could reasonably be associated or linked, directly or indirectly, with a particular
              consumer or device (&ldquo;personal information&ldquo;).
            </p>

            <p>
              <u>Information you provide to us</u>
            </p>

            <p>
              We collect information about you and your company as relevant for our business
              purposes, including manufacturing, selling, marketing, and delivering our products and
              services. Information we collect includes your name, addresses, email addresses, phone
              numbers, and payment card details.
            </p>

            <p>
              You may provide this information directly when purchasing our goods and services,
              visit contegospa.com (our “Website”), engage with us on various social media
              platforms, participate in sweepstakes or contests or other promotions, participate in
              our surveys, send us any feedback, questions, comments, or interact with us in any
              way, or in some cases another person (such as an administrator) on your behalf may
              provide it. If you provide information (including personal information) about someone
              else, you confirm that you have the authority to act for them and to consent to the
              collection and use of their personal information as described in this Privacy Policy.
            </p>

            <p>
              We record certain information and store it in log files when you visit our Website or
              interact with our services. This information may include Internet protocol or other
              device addresses or ID numbers as well as browser type, Internet service provider,
              domain names of referring/exit pages, operating system, date/time stamp, information
              you search for, locale and language preferences, your mobile carrier, and system
              configuration information. We collect and store this information to help us improve
              our Services.
            </p>

            <p>
              We use various technologies to collect information, including cookies that we save to
              your computer or mobile device. Cookies are small data files stored on your hard drive
              or in device memory. We use cookies to improve and customize our Services and your
              experience. We may also associate the information we store in cookies with personal
              information you submit. You can instruct your browser, by changing its options, to
              stop accepting cookies or to prompt you before accepting a cookie from websites you
              visit. If you do not accept cookies, however, you may not be able to use all aspects
              of our Website.
            </p>

            <p>
              <u>Information we collect from other sources</u>
            </p>

            <p>
              We may obtain information, including personal information, from third parties such as
              our partners and service providers, and combine it with other information we collect
              from you.
            </p>

            <h3>How We Use and Disclose Information We Collect</h3>

            <p>
              We may use and disclose the information we collect for a variety of purposes,
              including to:
            </p>

            <ul>
              <li>
                <p>
                  Provide, operate, maintain, improve, personalize, and promote our products and
                  services.
                </p>
              </li>
              <li>
                <p>
                  Provide, support, personalize, and develop our Website, products and services.
                </p>
              </li>
              <li>
                <p>Enable you to access and use our Website.</p>
              </li>
              <li>
                <p>
                  Process and complete transactions, and send you related information, including
                  estimates, purchase orders, purchase confirmations and invoices.
                </p>
              </li>
              <li>
                <p>
                  Communicate with you, including responding to your comments, questions, and
                  requests. provide customer service and support
                </p>
              </li>
              <li>
                <p>
                  Provide you with information about services, including technical notices, updates,
                  administrative messages, or advertising or marketing messages, and provide other
                  news or information about us and our select partners.
                </p>
              </li>
              <li>
                <p>
                  Monitor and analyze trends, usage, and activities in connection with our services.
                </p>
              </li>
              <li>
                <p>
                  Help maintain the safety, security, and integrity of our Website, products and
                  services, databases and other technology assets, and business.
                </p>
              </li>
              <li>
                <p>Investigate and prevent fraudulent transactions and other illegal activities.</p>
              </li>
              <li>
                <p>
                  Respond to law enforcement requests and as required by applicable law, court
                  order, or governmental regulations.
                </p>
              </li>
              <li>
                <p>As described to you when collecting your personal information.</p>
              </li>
            </ul>

            <p>
              We may also use the information we collect for other purposes about which we notify
              you but we will not use personal information we collected for purposes materially
              different, unrelated, or incompatible with the purposes described in this Privacy
              Policy without providing you notice.
            </p>

            <p>
              <u>We may share your information in the following ways</u>
            </p>

            <p>
              <i>Service Providers, Business Partners and Others:</i> We may share your information
              with service providers and other third parties who perform services on our behalf,
              such as marketing, advertising and solicitation services. We also provide your payment
              information to our service providers for payment processing and verification. Service
              providers such as analytics providers may collect information about your online
              activities over time and across different online services when you use our Services.
            </p>

            <p>
              <i>Compliance with Laws and Law Enforcement Requests; Protection of Our Rights:</i> We
              may disclose your information (including your personal information) to a third party
              if:
            </p>

            <ul>
              <li>
                <p>
                  We believe that disclosure is reasonably necessary to comply with any applicable
                  law, regulation, legal process or governmental request;
                </p>
              </li>
              <li>
                <p>To enforce our agreements and policies;</p>
              </li>
              <li>
                <p>To protect the security or integrity of our products and services;</p>
              </li>
              <li>
                <p>
                  To protect the property, rights, and safety of our customers or the public from
                  harm or illegal activities;
                </p>
              </li>
              <li>
                <p>
                  To investigate and defend ourselves against any third-party claims or allegations.
                </p>
              </li>
            </ul>

            <p>
              <i>Business Transfers: </i>We may share or transfer your information (including your
              personal information) in connection with, or during negotiations of, any merger, sale
              of company assets, financing, or acquisition of all or a portion of our business to
              another company. We will notify you of such a change in ownership or transfer of
              assets by posting a notice on our website.
            </p>

            <p>
              <i>Aggregate or Non-Personally identifying Data: </i>We may share aggregate or other
              non-personal information—information that does not directly identify you—with third
              parties in order to improve the overall experience of our Services.
            </p>

            <p>
              We have not sold any personal information, and we do not plan to do so in the future.
              If those plans change, this policy will be updated accordingly.
            </p>

            <p>
              <u>The Choices You Have With Your Information</u>
            </p>

            <p>
              You may decline to share certain personal information with us. You may update or
              correct your personal information at any time by contacting us. You may opt out of
              receiving promotional communications from us by using the unsubscribe link within each
              email. Even after you opt out from receiving promotional messages from us, you will
              continue to receive administrative messages from us regarding the products and
              services you purchased from us.
            </p>

            <p>
              You have the right to request that we delete any of your personal information we
              collected from you and retained, subject to certain exceptions. When we receive and
              confirm your verifiable consumer request, we will delete (and direct our service
              providers to delete) your personal information from our records, unless:
            </p>

            <ul>
              <li>
                <p>
                  Retaining the information is necessary for us or our service providers to complete
                  the transaction for which we collected the personal information, provide a good or
                  service that you requested, take actions reasonably anticipated within the context
                  of our ongoing business relationship with you, or otherwise perform our contract
                  with you.
                </p>
              </li>
              <li>
                <p>We retain the information to comply with a legal obligation.</p>
              </li>
              <li>
                <p>
                  We retain the information to make internal and lawful use of that information for
                  purposes compatible with the purpose for which, or content in which, you provided
                  it.
                </p>
              </li>
            </ul>

            <p>
              <i>California Residents Only.</i> The Shine the Light Act (California Civil Code
              sections 1798.83-1798.84) give California residents the right to ask us for
              information describing what categories of personal information we share with third
              parties for their direct marketing purposes. If you are a California resident and want
              to make such a request, please send an email to{' '}
              <a href="mailto:info@contegospa.com">info@contegospa.com</a> or submit a written
              request to the following address: Contego Spa Designs,{' '}
              <strong>12856 Brookhurst St Garden Grove, CA 92840</strong>.
            </p>

            <p>
              <u>Children’s Privacy</u>
            </p>
            <p>
              Our Services are not directed to individuals under 13. We do not knowingly collect
              personal information from children under 13. If we become aware that a child under 13
              has provided us with personal information, we will take steps to delete such
              information. If you become aware that a child has provided us with personal
              information, please contact us at{' '}
              <a href="mailto:contact@contegospa.com">contact@contegospa.com</a>.
            </p>

            <p>
              <u>No International Users</u>
            </p>

            <p>
              Our Website is hosted in the United States and intended for users located within the
              United States. If you choose to use access our Website or obtain our products and
              services from the European Union or other regions of the world with laws governing
              data collection and use that may differ from U.S. law, then please note that you are
              transferring your information outside of those regions to the United States. By
              providing your personal information, you consent to our use of that information in
              accordance with this Policy.
            </p>

            <p>
              <u>Third-party Websites</u>
            </p>

            <p>
              Our Website may contain links to third-party websites over which we have no control.
              Accessing such linked websites is done at your own risk. The privacy policies of such
              websites may differ from ours. You are encouraged to read the privacy policy of such
              linked websites before disclosing any information on such website.
            </p>

            <p>
              <u>Changes to this Privacy Policy</u>
            </p>

            <p>
              We may change this Privacy Policy from time to time. If we make any changes, we will
              notify you by revising the version and effective date at the top of this Privacy
              Policy and, in some cases, where appropriate we may provide you with additional notice
              (such as sending you an email notification). Your continued use of our services after
              the revised Policy has become effective indicates that you have read, understood, and
              agreed to the current version of this Privacy Policy.
            </p>

            <p>
              <u>Contact Information</u>
            </p>

            <p>
              Please contact us with any questions or comments about this Privacy Policy, our use
              and disclosure practices, or your consent choices regarding such use, or to exercise
              your rights under this Privacy Policy, by email to{' '}
              <a href="mailto:contact@contegospa.com">contact@contegospa.com</a> or by written
              submission to the following address: Contego Spa Designs,{' '}
              <strong>12856 Brookhurst St Garden Grove, CA 92840</strong>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy
